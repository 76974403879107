import React from 'react';
import { asyncComponent } from 'react-async-component';

const ChromaLayout = asyncComponent<{}>({
  resolve: () => new Promise((res, rej) => {
    require.ensure([], (require) => {
      const item = require('./Layout').default;
      res(item as React.ComponentClass<{}>);
    }, 'chroma-layout');
  })
});

export default ChromaLayout;
